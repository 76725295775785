<template>
  <div class="friendsCircle_publish_page">
    <ol class="list_box">
      <li class="list" @click="goPage(`friendsCircle/scheme?key=1`)">
        <div class="photo">
          <van-icon name="records" size="34" color="#e75b64" />
        </div>
        <div class="content van-hairline--bottom">
          <div class="title">发表预测&nbsp;<van-tag type="success">免审</van-tag>
          </div>
          <div class="desc">发表预测心水，不需要人工审核，即发即过</div>
        </div>
      </li>
      <li class="list" @click="goPage(`friendsCircle/scheme?key=2`)">
        <div class="photo">
          <van-icon name="photo-o" size="34" color="#e75b64" />
        </div>
        <div class="content van-hairline--bottom">
          <div class="title">规律预测&nbsp;<van-tag type="danger">带规律</van-tag>
          </div>
          <div class="desc">发表规律预测，需要上传规律图，规律预测会赢得更多的粉丝与鸡蛋奖励</div>
        </div>
      </li>
      <li class="list" @click="goPage(`friendsCircle/imageText?key=3&periodText=${periodText}`)">
        <div class="photo">
          <van-icon name="newspaper-o" size="34" color="#e75b64" />
        </div>
        <div class="content van-hairline--bottom">
          <div class="title">图纸图书预测&nbsp;<van-tag type="danger">带图纸图书</van-tag>
          </div>
          <div class="desc">分享即美德，赠人玫瑰，手留余香</div>
        </div>
      </li>
      <li class="list" @click="goPage(`friendsCircle/imageText?key=4&periodText=${periodText}`)">
        <div class="photo">
          <van-icon name="orders-o" size="34" color="#e75b64" />
        </div>
        <div class="content van-hairline--bottom">
          <div class="title">发表老母鸡汤
          </div>
          <div class="desc">交友、幽默、吐槽、总结、心灵鸡汤等</div>
        </div>
      </li>
    </ol>
    <p style="color:blue;font-size: 15px;" @click="goPage('manage')">《论坛管理范围》</p>
    <van-icon name="close" color="#999" size="34" class="close_btn" @click="goBack" />
  </div>
</template>

<script>

export default {
  data() {
    return {
      title: this.$route.meta.title,
      periodText: this.$route.query.periodText
    };
  },
  created() {
  },
  methods: {

  }
};
</script>

<style lang="less">
.friendsCircle_publish_page {
  .list_box {
    margin-top: 10px;
    .list {
      display: flex;
      align-items: center;
      width: 90%;
      margin: auto;
      .photo {
        width: 60px;
        height: 60px;
        border-radius: 50%;
        border: 2px solid #f5e9ed;
        background-color: #fffdfe;
        display: flex;
        justify-content: space-around;
        align-items: center;
      }
      .content {
        flex: 1;
        margin-left: 15px;
        height: 100px;
        display: flex;
        flex-wrap: wrap;
        align-content: center;
        .title {
          font-size: 16px;
        }
        .desc {
          margin-top: 5px;
          font-size: 14px;
          color: #666;
        }
      }
    }
  }
  .close_btn {
    position: fixed;
    bottom: 20px;
    left: 50%;
    margin-left: -20px;
  }
}
</style>
